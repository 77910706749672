import { useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';


function AuftragCodesSelector({ auftragscodesData, setAuf_code }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [filters, setFilters] = useState({
        vbf_nr: "",
        absender: "",
        empfaenger: "",
        versandbanhof: "",
        code: "",
        absender_kdnr: "",
        ebf_nr: "",
        empfaenger_kdnr: "",
        empfangsbahnhof: "",
        nhm_nr: "",
    });

    const handleClearFilter = () => setFilters({
        vbf_nr: "",
        absender: "",
        empfaenger: "",
        versandbanhof: "",
        code: "",
        absender_kdnr: "",
        ebf_nr: "",
        empfaenger_kdnr: "",
        empfangsbahnhof: "",
        nhm_nr: "",
    })

    const filteredAuftrags = auftragscodesData.filter(entry => {
        return (
            entry.vbf_nr.toLowerCase().includes(filters.vbf_nr.toLowerCase()) &&
            entry.absender.toLowerCase().includes(filters.absender.toLowerCase()) &&
            entry.empfaenger.toLowerCase().includes(filters.empfaenger.toLowerCase()) &&
            entry.versandbanhof.toLowerCase().includes(filters.versandbanhof.toLowerCase()) &&
            entry.code.toLowerCase().includes(filters.code.toLowerCase()) &&
            entry.absender_kdnr.toLowerCase().includes(filters.absender_kdnr.toLowerCase()) &&
            entry.ebf_nr.toLowerCase().includes(filters.ebf_nr.toLowerCase()) &&
            entry.empfaenger_kdnr.toLowerCase().includes(filters.empfaenger_kdnr.toLowerCase()) &&
            entry.empfangsbahnhof.toLowerCase().includes(filters.empfangsbahnhof.toLowerCase()) &&
            entry.nhm_nr.toLowerCase().includes(filters.nhm_nr.toLowerCase())
        );
    });

    const handleFilterChange = (field, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    function handleSelect(aufCode) {
        setAuf_code(aufCode)
        handleClose()
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Vordefinierte Auftragscodes
            </Button>

            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Vordefinierte Auftragscodes:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="VersandbahnhofNr">
                                <Form.Label>Versandbahnhof Nummer:</Form.Label>
                                <Form.Control placeholder="" value={filters.vbf_nr} onChange={(e) => handleFilterChange("vbf_nr", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="absender">
                                <Form.Label>Absender:</Form.Label>
                                <Form.Control placeholder="" value={filters.absender} onChange={(e) => handleFilterChange("absender", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="Empfanger">
                                <Form.Label>Empfänger:</Form.Label>
                                <Form.Control placeholder="" value={filters.empfaenger} onChange={(e) => handleFilterChange("empfaenger", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="Lieferort">
                                <Form.Label>Lieferort:</Form.Label>
                                <Form.Control placeholder="" value={filters.versandbanhof} onChange={(e) => handleFilterChange("versandbanhof", e.target.value)} />
                            </Form.Group>

                        </Row>
                        <Row className="mb-3">

                            <Form.Group as={Col} controlId="AuftragsCode">
                                <Form.Label>Auftrags Code:</Form.Label>
                                <Form.Control placeholder="" value={filters.code} onChange={(e) => handleFilterChange("code", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="absender_kdnr">
                                <Form.Label>absender_kdnr:</Form.Label>
                                <Form.Control placeholder="" value={filters.absender_kdnr} onChange={(e) => handleFilterChange("absender_kdnr", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="ebf_nr">
                                <Form.Label>ebf_nr:</Form.Label>
                                <Form.Control placeholder="" value={filters.ebf_nr} onChange={(e) => handleFilterChange("ebf_nr", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="empfaenger_kdnr">
                                <Form.Label>empfaenger_kdnr:</Form.Label>
                                <Form.Control placeholder="" value={filters.empfaenger_kdnr} onChange={(e) => handleFilterChange("empfaenger_kdnr", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="empfangsbahnhof">
                                <Form.Label>empfangsbahnhof:</Form.Label>
                                <Form.Control placeholder="" value={filters.empfangsbahnhof} onChange={(e) => handleFilterChange("empfangsbahnhof", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="nhm_nr">
                                <Form.Label>nhm_nr:</Form.Label>
                                <Form.Control placeholder="" value={filters.nhm_nr} onChange={(e) => handleFilterChange("nhm_nr", e.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Button variant='secondary' onClick={handleClearFilter}>Clear all filters</Button>
                        </Row>

                        <Table striped bordered hover size='sm' responsive="sm">
                            <thead>
                                <tr>
                                    <th><h6><b>Versandbahnhof Nr.</b></h6></th>
                                    <th><h6><b>Absender</b></h6></th>
                                    <th><h6><b>Empfänger</b></h6></th>
                                    <th><h6><b>Lieferort</b></h6></th>
                                    <th><h6><b>Auftrags Code</b></h6></th>
                                    <th><h6><b>absender_kdnr</b></h6></th>
                                    <th><h6><b>ebf_nr</b></h6></th>
                                    <th><h6><b>empfaenger_kdnr</b></h6></th>
                                    <th><h6><b>empfangsbahnhof</b></h6></th>
                                    <th><h6><b>nhm_nr</b></h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAuftrags.map((op) => {
                                    return (
                                        <tr key={op.id}>
                                            <td>{op.vbf_nr}</td>
                                            <td>{op.absender}</td>
                                            <td>{op.empfaenger}</td>
                                            <td>{op.versandbanhof}</td>
                                            <td>{op.code}</td>
                                            <td>{op.absender_kdnr}</td>
                                            <td>{op.ebf_nr}</td>
                                            <td>{op.empfaenger_kdnr}</td>
                                            <td>{op.empfangsbahnhof}</td>
                                            <td>{op.nhm_nr}</td>
                                            <td>
                                                <Button variant="primary" onClick={() => handleSelect(op)}>
                                                    Select
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AuftragCodesSelector;